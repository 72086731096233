console.log('init.js v2.7 ...');


//GLOBALS
var AKscroll;
var spt = getspt();

//INIT
(function init(){ console.log('init() ->');

  //INITFUNCTIONS
    //Start with a boilerplate
    boilerplate();

    //Show notice for outdated browsers
    akoutdated();

    //Set body classes
    $('body').akbildschirm(true);

    //Adminmenu Functions
    adminmenu(); //Set keyboard shortcuts
    hidemessages(); //Set keyboard shortcuts
    akadminbutton();

    //Process Forms
    $('.webform-container-inline').akwrapformitems('.form-item'); //?


    //document.addEventListener('touchmove', function (e) { e.preventDefault(); }, false);


  //Ajax

  // $('.field-name-field-navfields a').akajax({
  //   closestSelector: '#container',
  //   findSelector: '.ajax',
  //   resultSelector: '#ajaxresult>article.node',
  //   transitions: true,
  //   ajaxcontainer: 'append',
  //   toggle: false,
  //   callbackIfExist: true,
  //   reloadIfExist: true,
  //   callback: function(){
  //     $('html, body').animate({
  //      scrollTop: $(".ajax").offset().top
  //    }, 250);
  //   }
  // });

  //$('.view-events a, .view-events-kids a').akarm('article.node');

  relatedfull_init();
  // $('.view-relatedfull>.node').akswiperwrap()

  //BASIC
    //Init Sandwich-Button
    $('.sandwich').aksandwich('.sandwich-button');

    //Init jPlayers
    $('.akjplayerA').akjplayerA();
    $('.akjplayerV').akjplayerV();

    //Init Swipers
    $('.view-featured').akViewSwiper();
    $('.node-werkgruppe>.element>.field-collection-container').akRelatedSwiper();
    $('.field-name-field-images-swiper').akImageSwiper();

    //$('#AKonepagenav').akonepagenav('.view-pageslide', 'article.node', '.field.title');

    relatedfull_goto();

  //EXTRA
    // $('#node-10 .jp-controls').remove();
    // $('#node-10 .jp-container').bind($.jPlayer.event.ended, function(event) {
    //   $(this).jPlayer("play");
    // });


    // $('.swiper-slide-active .akjplayerV .jp-container').bind($.jPlayer.event.ready, function(event) { $(this).jPlayer("play"); });

    // AKscroll = new IScroll('#wrapper', {}); akscrollbeat(); console.dir(AKscroll.options);
    tags();

    akadminbutton();


  //EVENTS
  buttons();

  //Remove Ajax
  removeajaxresult();

  //Start Beatmakers
  //startbeatmaker(10);
  //startscrollbeat();

  //FIXES
  if( $('#overlay-container').length ) stopautoplayswipers();

})();

//CALLBACKS

  //Swipers
  function akViewSwiper_onSlideChangeStart(swiper){
    $('.akjplayerV .jp-container').jPlayer('pause');
  }

  function akViewSwiper_onSlideChangeEnd(swiper){
    $('.swiper-slide-active .akjplayerV .jp-container').jPlayer('play');
  }

  function akRelatedSwiper_onSlideChangeStart(swiper){
    $('.akjplayerV .jp-container').jPlayer('pause');
  }

  function akRelatedSwiper_onSlideChangeEnd(swiper){
    $('.swiper-slide-active .akjplayerV .jp-container').jPlayer('play');
  }

  //Fix for exposed views filter
  $(document).ajaxComplete(function() { console.log('ajaxComplete')
    if( $('.content.view').parent().is('div') ) $('.content.view').unwrap();
  });


//BEATMAKER

  function beatmaker(){
    //EVENTS
    console.log('tick');
  }


  function scrollbeat(){
    //EVENTS
    //spt = getspt(); //check for global
  }


  // function akscrollbeat(){
  //   AKscroll.on('scrollEnd', function () {
  //     console.log('SCROLL: '+this.y)
  //   });
  // }


//under the lid

  function startscrollbeat(){
    $(window).scroll(function(){
      scrollbeat();
    });
  }
  function startbeatmaker(fps){
    window.setTimeout(beatmaker, 1000 / fps);
    beatmaker();
  }
