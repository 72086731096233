console.log('_akswiper.js v2.7 (mod) ...');

//VIEWSWIPER
$.fn.akViewSwiper = function(){ console.log('akViewSwiper() ->');

  this.each(function(index, el) {

    var $that = $(this);

    $that.addClass('akviewswiper').addClass('akviewswiper'+index);

    $that.find('.swiper-container:first>.swiper-wrapper').find('>.swiper-slide').each(function(index, el) {
      //$(this).attr('data-hash', $(this).find('.field.title:first').text().replace(/\W+/g, '') );
      if ( $(this).find('>article.node').attr('about') ){
        hash = $(this).find('>article.node').attr('about').split('/').pop();
        $(this).attr('data-hash', hash);
        console.log('akviewswiper() -> #hash is '+hash)
      }
      else console.log('akviewswiper() -> no #hash here')
    });

    $that.find('>.swiper-container').swiper({
      direction: 'horizontal',
      slidesPerView: 1,

      loop: true,

      autoplay: 8000,

      hashnav: false,

      shortSwipes: true,
      longSwipes: true,
      touchAngle: 20,

      grabCursor: true,

      nextButton: $that.find('>.swiper-container>.swiper-button-next'),
      prevButton: $that.find('>.swiper-container>.swiper-button-prev'),

      pagination: $that.find('.view-swiper-pagination'),
      paginationClickable: true,
      paginationBulletRender: function (index, className) {
        return '<span class="' + className + '">' + $that.find('>.swiper-container>.swiper-wrapper>.swiper-slide').eq(index).find('.field.title:first').text() +'</span>';
      }
    });

    var thisSwiper = $that.find('>.swiper-container')[0].swiper;

    thisSwiper.on('onSlideChangeStart', function(swiper) {
      akViewSwiper_onSlideChangeStart(swiper);
    });

    thisSwiper.on('onSlideChangeEnd', function(swiper) {
      akViewSwiper_onSlideChangeEnd(swiper);
    });
  }); //each
};



//VIEWSWIPER
$.fn.akRelatedSwiper = function(){ console.log('akRelatedSwiper() ->');

  this.each(function(index, el) {

    var $that = $(this);

    $that.addClass('akrelatedswiper').addClass('akrelatedswiper'+index);

    if ( $that.find('>.swiper-container>.swiper-wrapper>.swiper-slide').length < 2){ console.log('only one img here – nothing to swipe');
      $that.addClass('only-one-slide');
      $that.find('>.swiper-container>.swiper-wrapper>.swiper-slide').addClass('only-swiper-slide');
      $that.closest('.akrelatedswiper').find('>.swiper-container>.swiper-button').remove();
      return;
    }

    $that.find('>.swiper-container>.swiper-wrapper>.swiper-slide').each(function(index, el) {
      //$(this).attr('data-hash', $(this).find('.field.title:first').text().replace(/\W+/g, '') );
      $(this).attr('data-hash', index);
      // if ( $(this).find('.field-name-field-title').length ){
      //   hash = $(this).find('.field-name-field-title').text().split('/').pop();
      //   $(this).attr('data-hash', hash);
      //   console.log('akRelatedSwiper() -> #hash is '+hash)
      // }
      //else console.log('akRelatedSwiper() -> no #hash here')
    });

    $that.find('>.swiper-container').swiper({
      direction: 'horizontal',
      slidesPerView: 1,
      loop: false,

      hashnav: true,

      shortSwipes: false,
      longSwipes: true,
      touchAngle: 20,

      nextButton: $that.find('>.swiper-container>.swiper-button-next'),
      prevButton: $that.find('>.swiper-container>.swiper-button-prev'),
      pagination: $that.find('.view-swiper-pagination'),

      paginationClickable: true,
      paginationBulletRender: function (index, className) {
        return '<span class="' + className + '">' + $that.find('>.swiper-container>.swiper-wrapper>.swiper-slide').eq(index).find('.field.title:first').text() +'</span>';
      }

    });

    var thisSwiper = $that.find('>.swiper-container')[0].swiper;

    thisSwiper.on('onSlideChangeStart', function(swiper) {
      akRelatedSwiper_onSlideChangeStart(swiper);
    });

    thisSwiper.on('onSlideChangeEnd', function(swiper) {
      akRelatedSwiper_onSlideChangeEnd(swiper);
    });
  }); //each
};


//IMAGESWIPER
$.fn.akImageSwiper = function(){ console.log('akimageswiper() ->');

  this.each(function(index, el) {

    var $that = $(this);

    $that.addClass('akimageswiper').addClass('akimageswiper'+index);

    //$that.find('.swiper-slide img').akCalculateImage('stretch',1);

    // if ( $that.find('.swiper-slide').length < 2){ console.log('only one img here – nothing to swipe');
    //   $that.addClass('only-one-slide');
    //   $that.find('.swiper-slide').addClass('only-swiper-slide');
    //   $that.closest('.akimageswiper').find('.swiper-button').remove();
    //   return;
    // }

    if ( $that.find('>.swiper-container>.swiper-wrapper>.swiper-slide').length < 2){ console.log('only one img here – nothing to swipe');
      $that.addClass('only-one-slide');
      $that.find('>.swiper-container>.swiper-wrapper>.swiper-slide').addClass('only-swiper-slide');
      $that.closest('.akimageswiper').find('>.swiper-container>.swiper-button').remove();
      return;
    }

    $that.find('.swiper-container').swiper({
      direction: 'horizontal',
      slidesPerView: '1',
      centeredSlides: true,
      nested: true,
      //freeMode: true,

      // effect: 'fade',
      // fade: {
      //   crossFade: true
      // },
      // loop: true,

      //autoplay: 4000,

      shortSwipes: true,
      longSwipes: true,
      touchAngle: 20,

      grabCursor: false,

      mousewheelControl: false,
      mousewheelSensitivity: 1,

      nextButton: $that.find('.swiper-button-next'),
      prevButton: $that.find('.swiper-button-prev')

    });

    var thisSwiper = $that.find('.swiper-container')[0].swiper;

    thisSwiper.on('click', function(swiper){ console.log('$thisSwiper on click -> '+ thisSwiper.clickedIndex);
      if (typeof thisSwiper.clickedIndex !== 'undefined'){
        //thisSwiper.slideTo( thisSwiper.clickedIndex );
        thisSwiper.slideNext();
      }
    });

  }); //each
};


















//IMAGEFADER
$.fn.akImageFader = function(time, closesestelement){ console.log('akimagefader() ->');
  excludeLinks = typeof closesestelement !== 'undefined' ? closesestelement : false;

  this.each(function(index, el) {

    var $that = $(this);

    $that.addClass('akimagefader').addClass('akimagefader'+index);

    $that.find('.fader-slide').find('img').akCalculateImage('fill');

    var $active, $next, $prev;

    $that.find('.fader-slide:first').addClass('akimagefader-active');
    setClasses();

    function toNext(){
      if (closesestelement != false) if ( $that.closest(closesestelement).length == 0 ) return;

      console.log('akimagefader() -> toNext');

      setClasses();

      setTimeout(function(){
        //if ( !$that.find('.akimagefader-prev') ) return false;
        $next.addClass('akimagefader-active').removeClass('akimagefader-next');
        $active.addClass('akimagefader-prev').removeClass('akimagefader-active');
        $prev.removeClass('akimagefader-prev');
      },1000);
    }

    function setClasses(){

      $active = $that.find('.fader-slide.akimagefader-active');
      $next = $active.next('.fader-slide').length ? $active.next('.fader-slide') : $that.find('.fader-slide:first')
      $next.addClass('akimagefader-next');
      $prev = $active.prev('.fader-slide').length ? $active.prev('.fader-slide') : $that.find('.fader-slide:last')
      $prev.addClass('akimagefader-prev');

    }

    // $that.on('click', function(event) {
    //   event.preventDefault();
    //   toNext();
    // });

    var animated = setInterval(function(){
      toNext()
    }, time);

  });
}

//$('.field-name-field-images-fader').akImageFader(4000, '.swiper-slide-active');
