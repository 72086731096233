console.log('_aksandwich.js v2.7.1 ...');

$.fn.aksandwich = function(button){ console.log('aksandwich() ->');

  if (button == 'up' || button == 'down' || button == 'toggle'){

    if ( $('.aksandwich').length )
      $sandwich = $('.aksandwich');
      $button = $('.aksandwich-button');

      if (button == 'up'){
        $sandwich.slideUp(200);
        $('.aksandwich-indicator').addClass('aksandwich-hidden').removeClass('aksandwich-opened');
      }
      else if (button == 'down'){
        $sandwich.slideDown(200);
        $('.aksandwich-indicator').removeClass('aksandwich-hidden').addClass('aksandwich-opened');
      }
      else if (button == 'toggle'){
        $sandwich.slideToggle(200);
        $('.aksandwich-indicator').toggleClass('aksandwich-hidden').toggleClass('aksandwich-opened');
      }

    else{ console.log('no sandwich to up/down'); return false };

    return;
  }

  $button = $(button).addClass('aksandwich-button aksandwich-indicator');

  this.each(function(index){

    $sandwich = $(this);

    $sandwich.addClass('aksandwich aksandwich'+index+' aksandwich-indicator').hide();

    if ( !$sandwich.is(':visible') ){
      $('.aksandwich-indicator').addClass('aksandwich-hidden').removeClass('aksandwich-opened');
    }

  });

  // // Swipe-Support
  // $('.aksandwich-button, .aksandwich').swipe( {
  //   tap:function(event, target){ console.log('aksandwich() -> tap/click 2 '+$(event.target).attr('class'));
  //     if ( !$(target).closest('.aksandwich-button').length ) return false;
  //     $('.aksandwich').aksandwich('toggle');
  //   },
  //   swipe:function(event, direction, distance, duration, fingerCount) { console.log('aksandwich() -> swipe '+direction+' '+$(event.target).attr('class'));
  //     if (direction == 'up') {
  //       $('.aksandwich').aksandwich('up');
  //     }
  //     else if (direction == 'down'){
  //       $('.aksandwich').aksandwich('down');
  //     }
  //   },
  //   // swipeStatus:function(event, phase, direction, distance, duration, fingers, fingerData) { console.log('aksandwich() -> swipeStatus '+direction+' '+$(event.target).attr('class'));
  //   //   console.log(distance);
  //   //   // if (direction == 'down'){
  //   //   //   $('.aksandwich').css('height', distance+10+'px');
  //   //   // }
  //   // },
  //   threshold:5
  // });

  // Swipe-Support
  $('.aksandwich-button, .aksandwich').on('click', function(event){
    console.log('aksandwich() -> click '+$(event.target).closest('.aksandwich-button').length );
    if ( !$(event.target).closest('.aksandwich-button').length ) return true;
    $('.aksandwich').aksandwich('toggle');
  });

  //   swipe:function(event, direction, distance, duration, fingerCount) { console.log('aksandwich() -> swipe '+direction+' '+$(event.target).attr('class'));
  //     if (direction == 'up') {
  //       $('.aksandwich').aksandwich('up');
  //     }
  //     else if (direction == 'down'){
  //       $('.aksandwich').aksandwich('down');
  //     }
  //   },
  //   // swipeStatus:function(event, phase, direction, distance, duration, fingers, fingerData) { console.log('aksandwich() -> swipeStatus '+direction+' '+$(event.target).attr('class'));
  //   //   console.log(distance);
  //   //   // if (direction == 'down'){
  //   //   //   $('.aksandwich').css('height', distance+10+'px');
  //   //   // }
  //   // },
  //   threshold:5
  // });

};
// Example Init: $('.sandwich').aksandwich('.sandwich-button');
// Example Event: $('.sandwich').aksandwich('down');
