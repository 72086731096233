console.log('_ajax v2.7 ...');

$.fn.akajax = function(options, transitions){ console.log('akajax() ->');

  if (options == 'toggle'){
    animated = typeof animated !== 'undefined' ? transitions : false;
    akajaxToggle(this, transitions);
    return
  }

  var option = $.extend({
    closestSelector: 'div',
    findSelector: false,
    resultSelector: '#ajaxresult',
    ajaxcontainer: 'hide', //'prepend'. 'append'
    toggle: true, //clearContainer should be false
    reloadIfExist: false,
    callbackIfExist: false,
    transitions: false,
    callback: function(){
      console.log('no callback');
    }
  }, options );

  this.each(function(index, el) {

    var $that = $(this);

    $that.addClass('akajax-armed')

    $that.on('click', function(event) {
      event.preventDefault();

      $sel = $(this).closest(option.closestSelector)

      if ( option.findSelector != false) $sel = $sel.find(option.findSelector);

      if( $sel.hasClass('akajax-loaded') && option.toggle == true ){
        akajaxToggle($sel, options.transitions)
        // if (options.transitions) $sel.find('div.akajax-box').slideToggle('fast');
        // else $sel.find('div.akajax-box').toggle();
        // $sel.toggleClass('akajax-closed');
      }

      if( $sel.hasClass('akajax-loaded') && option.reloadIfExist == false ){ console.log('.akajax already existed');
        if(option.callbackIfExist){
          if (typeof option.callback == 'function') {
            option.callback.call(this);
          }
        }
        return false;
      }

      $sel.css('min-height', $sel.height()+'px');
      $sel.find('.akajax-box').remove();

      $sel.addClass('akajax').addClass('akajax-loading');

      if (option.ajaxcontainer == 'hide' ){
        $sel.children().addClass('akajax-hidden').hide();
        $sel.append('<div class="akajax-box"></div>').show();
      }
      else if (option.ajaxcontainer == 'clear' ) $sel.html('<div class="akajax-box"></div>');
      else if (option.ajaxcontainer == 'prepend') $sel.prepend('<div class="akajax-box"></div>');
      else if (option.ajaxcontainer == 'append') $sel.append('<div class="akajax-box"></div>');

      if (options.transitions) $sel.find('div.akajax-box').hide();

      $sel.find('div.akajax-box').load( $that.attr('href')+' '+option.resultSelector, function(response, status, xhr){

        $sel.removeClass('akajax-loading').removeClass('akajax-error');

        if (status == "error") {
          console.error('Error'+ xhr.status +' '+ xhr.statusText );
          $sel.addClass('akajax-error');
        }
        else {
          $sel.addClass('akajax-loaded');
        }

        if (typeof option.callback == 'function') {
          option.callback.call(this);
        }

        if (options.transitions) $sel.find('div.akajax-box').slideDown('fast');

      });

    });
  });

  function akajaxToggle($sel, transitions){ console.log('akajaxToggle() ->');
    //transitions: full, half
    if ( !$sel.hasClass('akajax') ){ console.error('This is not (yet) an .akajax'); return; }
    if ( !$sel.hasClass('akajax-loaded') ){ console.error('This .akajax is not (yet) loaded'); return; }

    transitions = typeof transitions !== 'undefined' ? transitions : false;

    if (transitions == 'full'){
      $sel.find('.akajax-hidden').slideToggle('fast');
      $sel.find('div.akajax-box').slideToggle('fast');
    }
    else if (transitions == 'half'){
      $sel.find('.akajax-hidden').toggle();
      $sel.find('div.akajax-box').slideToggle('fast');
    }
    else{
      $sel.find('.akajax-hidden').toggle();
      $sel.find('div.akajax-box').toggle();
    }
    $sel.toggleClass('akajax-closed');
  }

};

//END
