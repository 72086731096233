console.log('_events.js ...');

function buttons(){

  $('body.desktop .sandwich-button').hover(function(){
    if ($(this).hasClass('aksandwich-opened') ) return;
    $('.logo span').hide();
    $('.logo span.info').show();
  }, function(){
      $('.logo span').hide();
      $('.logo .mbs').show();
  })

  $('body.desktop .everything-button').hover(function(){
    $('.logo span').hide();
    $('.logo .everything').show();
  }, function(){
      $('.logo span').hide();
      $('.logo span.mbs').show();
  })


  //.scrolldown
  $(document).on('click', '.scrolldown', function(event) {
    $('body,html').animate({ scrollTop: $(this).offset().top+$(this).height() }, 500);
  })


}





function relatedfull_init(){ console.log('rearange_relatedfull() ->');
  if( !$('.view-relatedfull').length ) return;

  var $onode = $('#content').find('>.node:first');
  var ID = $onode.attr('id');
  $onode.remove();

  $('.view-relatedfull').attr('data-goto', ID);

}

function relatedfull_goto(){
  if( !$('.view-relatedfull').length ) return;

  $('.view-relatedfull:first').each(function(index) {
    if( typeof $(this).attr('data-goto') == 'undefined' || $(this).attr('data-goto') == '' ){ console.error('no data-goto')
      return;
    }

    var index = $(this).find('#'+$(this).attr('data-goto')).closest('.swiper-slide').index();

    $('.view-relatedfull>.swiper-container').get(0).swiper.slideTo(index, 0);

  });

}



function tags(){

  tagem( cleansting( window.location.hash.substring(1) ) );


  $(document).on('click','.view-everything .view-filters a', function(event) {
    event.preventDefault();

    if ( $(this).hasClass('active') ){ console.log('buh')
      $('.view-everything .view-filters a.active').removeClass('active');
      $('.view-everything .row.deactivated').removeClass('deactivated');
    }
    else{
      tagem( cleansting( $(this).text()) )
    }
    tagfilter();
  });


  $(document).on('click', '.field-name-field-tags div.item', function(event) {
    event.preventDefault();
    window.location.href = Drupal.settings.basePath+"everything#"+cleansting( $(this).text() );
  })


  function tagem(tag){ console.log('tagem() -> '+tag)
    if (tag == '') return;

    activelink(tag);

    $('.view-everything .row').each(function(index) {

      $(this).addClass('deactivated');

      $(this).find('.views-field-term-node-tid').find('li').each(function(index) {
        if( cleansting( $(this).text() ) == tag ){
          $(this).closest('.row').removeClass('deactivated')
        }
      });

    });
    tagfilter();
  }

  function activelink(tag){
    $('.view-everything .view-filters a').removeClass('active')

    $('.view-everything .view-filters a').each(function(index) {
      if ( cleansting( $(this).text() ) == tag) $(this).addClass('active')
    });
  }

  function cleansting(string){
    return string.replace(/\s+/g, '').toLowerCase()
  }


  function tagfilter(){
    $('.view-everything .view-filters .bef-select-as-links>.form-item').each(function(){

      if ( $(this).find('a.active').length > 0 ){
        $(this).addClass('hasactive')
      }
      else {
        $(this).removeClass('hasactive')
      }

    });
  }
}





// $.fn.akswiperwrap = function(){ console.log('akswiperwrap() ->');
//   this.wrapAll('<div class="swiper-container"><div class="swiper-wrapper"></div></div>');
//   this.closest('.swiper-container').append('<div class="swiper-button-next"><div class="swiper-button-prev">')
//   this.each(function(index) {
//     $(this).wrap('<div class="swiper-slider"></div>');
//   });
// }
//
